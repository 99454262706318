
import React from 'react'
import PolicyContent from '../../../../components/default/PolicyContent'
import { useDocumentTitle } from '../../../../hooks'

const Ebiore = () => {
 useDocumentTitle('Ebiore Privacy Policy');
    return (
        <>  
            <PolicyContent />
        </>
    ) 
}
export default Ebiore;
 