
import React from 'react';
import Slider from '../../components/Hero/Slider'
import Intro from '../../components/Intro/Intro'
import ServicesContainer from '../../components/Services/ServicesContainer'
import Counter from '../../components/Counter/Counter'
import Deals from '../../components/Deals/Deals'
import Client from '../../components/Clients/Client'
import Testimonials from '../../components/Testimonials/Testimonials'
import { useDocumentTitle, useScrollTop } from '../../hooks';
import logo from '../../images/clients/bestdeal-logo.png';
import mozartren from '../../images/clients/mozartren.png';
import { illia, aslan } from '../../images/interns';


const testimonies = [
	{
		id: 0,
		title: "Fantastic Learning Experience",
		content: "My time at Luday for the internship was a fantastic learning experience. I got to dive into some really cool and fresh projects, and the teamwork vibe among us made every task a blast. The Luday team were super supportive and always there to guide me when I needed help. Leaving Luday, I've got a bunch of new skills under my belt and some awesome memories. Thanks for giving me a transformative experience that mixed professional growth with a whole lot of fun.",
		name: "Muhmmad Aslan",
		jd: "Intern (Backend developer)",
		image: aslan
	},
    {
		id: 1,
		title: "Good Communication And Creative Ideas ",
		content: "Thank you Luday AB for the wonderful service you offered me to build my company website. Your good communication and creative ideas were just so mind-blowing. The quality of the final product was far more than I expected. The website is easy to navigate, has a clear and intuitive layout, with well-organized menus and navigation bars. I receive so many compliments from customers about the website.",
		name: "Eveline Ngwa",
		jd: "CEO, Mozart Rengöring",
		image: mozartren
	},
    {
		id: 2,
		title: "Good Product Delivery",
		content: "Collaborating with Luday AB to build my e-commerce website was a delightful experience. Their attention to detail and creativity translated my vision into a visually impressive and highly functional final product that exceeded my expectations.",
		name: "Gbolahan Adekunle",
		jd: "CEO, BestDealNaija",
		image: logo
	},
    {
		id: 3,
		title: "Thank You",
		content: "This internship opportunity played an important role in finding my first job and becoming better as a developer. And nice to meet you, guys! With kind regards.",
		name: "Illia Zolotukha",
		jd: "Intern (Full-stack developer)",
		image: illia
	}
];

function Home() {
    useDocumentTitle('Home | Luday AB');
    useScrollTop();
	return (
		<div className="app">
            < Slider  />
            < Intro  />
            < ServicesContainer  />
            < Counter  />
            < Client  />
            <Testimonials testimonies={testimonies} subtitleText='Testimonials' titleText='What people say about us' />
            < Deals  />
		</div>
	);
}
export default Home;
