
import React from 'react';
import Banner from '../../../components/Banner/Banner'
import ServicesContainer from '../../../components/Services/ServicesContainer'
import Portfolio from '../../../components/Portfolio/Portfolio'
import Deals from '../../../components/Deals/Deals'
import { useDocumentTitle, useScrollTop } from '../../../hooks';


const Services = () => {
  useDocumentTitle('Services | Luday AB');
  useScrollTop();
  return (
	<div className="app">
        < Banner  titleText='Our Solutions' subtitleText='Services'/>
        < ServicesContainer bgColor='bg-white' textClass='mt-2 text-3xl font-bold tracking-tight sm:text-4xl text-gray-900' discriptionClass='mt-4 xl:mt-6 text-gray-900 text-lg'  />
        < Portfolio />
        < Deals  />
	</div>
  )
}
export default Services;
