
import React from 'react';
import Banner from '../../../components/Banner/Banner'
import AffiliateHero from './AffiliateHero'
import AffiliateOverview from './AffiliateOverview'
import Tiers from './Tiers'
import AffiliateExtra from './AffiliateExtra'
import { useDocumentTitle, useScrollTop } from '../../../hooks';


const Affiliate = () => {
    useDocumentTitle('Affiliate Marketing | Luday AB');
    useScrollTop();
    return (
       <div className="app">
          < Banner  titleText='About Our Affiliate Marketing'  subtitleText='Affiliate'/>
          <AffiliateHero/>
          <AffiliateOverview />
          <Tiers />
          <AffiliateExtra />
       </div>
    )
}
export default Affiliate;
