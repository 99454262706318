
import React from 'react'

const Map = () => {
    
  return (
        <>
            <div className="flex items-left justify-start bg-white bg-opacity-50 mt-5">
                <iframe title='Luday address' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2131.5804817726566!2d11.941373677423774!3d57.706596939838946!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x464ff35b210610dd%3A0xaf10eb97571aa9a4!2sTheres%20Svenssons%20gata%2013%2C%20417%2055%20Göteborg!5e0!3m2!1sen!2sse!4v1709122165505!5m2!1sen!2sse" width="100%" height="600" style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </>
    )
}

export default Map
 