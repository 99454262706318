
import React from 'react';
import { EnvelopeIcon, PhoneIcon, PhoneArrowDownLeftIcon } from '@heroicons/react/20/solid';
import { Link } from 'react-router-dom';

const ContactSidebar = () => {
	return (
		<>
			<div className='flex flex-col h-auto from-gray-100 via-gray-100 to-gray-100 bg-gradient-to-br'>
				<div className="mx-8 mt-10 items-center justify-center w-auto h-auto rounded-xl group space-x-6 bg-white bg-opacity-50 shadow-sm hover:rounded-2xl">
					<ul className='my-9'>
						<li className='p-5 text-base md:text-lg tracking-tight flex flex-row items-center flex-wrap'>
							<div className='border-solid rounded-full border-2 p-1 border-[#01015D] m-2'>
								<EnvelopeIcon className='h-3 md:h-4 text-[#01015D] m-1 block'/>
							</div>
							<a href="mailto:info@luday.se">info@luday.se</a>
						</li>
					</ul>
				</div>
				<div className="m-7 ">
					<h5 className=' py-10 text-3xl font-bold tracking-tight text-gray-900 flex justify-center'>We are social</h5>
					<div className="mt-1 ">
						<div className="space-y-2  pr-5">
							<div className="space-y-4">
								<div className="mx-auto text-center mt-2">
									<ul className="flex justify-center mb-4 md:mb-0">
										<li className='flex items-center gap-2'>
											<a href='https://www.linkedin.com/company/luday/' target='_blank' rel='noreferrer' className="flex justify-center items-center text-[#013289] hover:text-gray-900 bg-white hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out w-10 h-10" aria-label="Linkedin">
                                            <svg className="w-5 h-5 fill-current" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 50 50" width="50px" height="50px">
                                                <path d="M41,4H9C6.24,4,4,6.24,4,9v32c0,2.76,2.24,5,5,5h32c2.76,0,5-2.24,5-5V9C46,6.24,43.76,4,41,4z M17,20v19h-6V20H17z M11,14.47c0-1.4,1.2-2.47,3-2.47s2.93,1.07,3,2.47c0,1.4-1.12,2.53-3,2.53C12.2,17,11,15.87,11,14.47z M39,39h-6c0,0,0-9.26,0-10 c0-2-1-4-3.5-4.04h-0.08C27,24.96,26,27.02,26,29c0,0.91,0,10,0,10h-6V20h6v2.56c0,0,1.93-2.56,5.81-2.56 c3.97,0,7.19,2.73,7.19,8.26V39z"/>
                                            </svg>
											</a>
											<a href='https://twitter.com/ludaysocial' target='_blank' rel='noreferrer' className="flex justify-center items-center text-[#013289] hover:text-gray-900 bg-white hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out w-10 h-10" aria-label="X">
                                                <svg className="w-5 h-5 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="50px" height="50px">
                                                    <path d="M 11 4 C 7.134 4 4 7.134 4 11 L 4 39 C 4 42.866 7.134 46 11 46 L 39 46 C 42.866 46 46 42.866 46 39 L 46 11 C 46 7.134 42.866 4 39 4 L 11 4 z M 13.085938 13 L 21.023438 13 L 26.660156 21.009766 L 33.5 13 L 36 13 L 27.789062 22.613281 L 37.914062 37 L 29.978516 37 L 23.4375 27.707031 L 15.5 37 L 13 37 L 22.308594 26.103516 L 13.085938 13 z M 16.914062 15 L 31.021484 35 L 34.085938 35 L 19.978516 15 L 16.914062 15 z"/>
                                                </svg>
											</a>
											<a href='https://www.facebook.com/ludaysocial' target='_blank' rel='noreferrer' className="flex justify-center items-center text-[#013289] hover:text-gray-900 bg-white hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out w-10 h-10" aria-label="Facebook">
                                                <svg className="w-5 h-5 fill-current" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 50 50" width="50px" height="50px">    
                                                    <path d="M41,4H9C6.24,4,4,6.24,4,9v32c0,2.76,2.24,5,5,5h32c2.76,0,5-2.24,5-5V9C46,6.24,43.76,4,41,4z M37,19h-2c-2.14,0-3,0.5-3,2 v3h5l-1,5h-4v15h-5V29h-4v-5h4v-3c0-4,2-7,6-7c2.9,0,4,1,4,1V19z"/>
                                                </svg>
											</a>
											<a href='https://www.instagram.com/ludayx/' target='_blank' rel='noreferrer' className="flex justify-center items-center text-[#013289] hover:text-gray-900 bg-white hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out w-10 h-10" aria-label="Instagram">
                                                <svg className="w-5 h-5 fill-current" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 50 50" width="50px" height="50px">
                                                    <path d="M 16 3 C 8.83 3 3 8.83 3 16 L 3 34 C 3 41.17 8.83 47 16 47 L 34 47 C 41.17 47 47 41.17 47 34 L 47 16 C 47 8.83 41.17 3 34 3 L 16 3 z M 37 11 C 38.1 11 39 11.9 39 13 C 39 14.1 38.1 15 37 15 C 35.9 15 35 14.1 35 13 C 35 11.9 35.9 11 37 11 z M 25 14 C 31.07 14 36 18.93 36 25 C 36 31.07 31.07 36 25 36 C 18.93 36 14 31.07 14 25 C 14 18.93 18.93 14 25 14 z M 25 16 C 20.04 16 16 20.04 16 25 C 16 29.96 20.04 34 25 34 C 29.96 34 34 29.96 34 25 C 34 20.04 29.96 16 25 16 z"/>
                                                </svg>
											</a>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
};

export default ContactSidebar;
 