
import React from 'react';


const Banner = () => {
  return (
	<>
		<section className="bg-white mb-20">
            <div className="container max-w-7xl px-6 mx-auto">
                <p className="mt-4 text-lg leading-8 text-gray-600">
                    An industry leader in software development services, Luday AB, was established with the goal of giving individuals, businesses, and organizations the best software solutions possible. They have been in business for more than three years, and experts is developing custom software solutions for their clients using the most recent technologies and platforms.
                </p>
                <p className="mt-4 text-lg leading-8 text-gray-600">
                    We have a team of skilled and experienced group of software engineers, developers, and designers who work with the goal of providing our customers with the best software solutions possible. They are highly skilled in a variety of software development services, such as web development, mobile app development, database development, and others. Additionally, they provide cloud-based services like SaaS and PaaS.
                </p>
                <p className="mt-4 text-lg leading-8 text-gray-600">
                    The primary objective of Luday AB is to deliver services of the highest quality level. We take the time to fully understand the requirements of each client before creating software solutions that are specifically suited to their needs. To help our clients make the most of their software solutions, we also offer ongoing support and maintenance.
                </p>
                <p className="mt-4 text-lg leading-8 text-gray-600">
                    In an effort to stay on the cutting edge of technology, Luday AB is constantly considering ways to enhance its services. What distinguishes us from other software companies is our dedication to excellence and innovation.
                </p>
                <p className="mt-4 text-lg leading-8 text-gray-600">
                    If you are looking for a software company that is dedicated to providing the best possible solutions, then look no further than Luday AB. With our experienced team and commitment to excellence, you can be sure that we will provide you with the best software solutions for your business.
                </p>
            </div>
        </section>  
	</>
  );
}

export default Banner;
 