
import React from 'react';
import Webmobiledesign from "../../../images/services/websitedesign.png";
import Enbededsoftware from "../../../images/services/enbeded-software.png";
import Omarket from "../../../images/services/online-marketing.png";
import Nmarket from "../../../images/services/network-marketing.png";
import Uiuxdesign from "../../../images/services/ui-ux-design.png";
import ProjectManagement from "../../../images/services/prmanagement.png";
import Dataanalytics from "../../../images/services/data-analytics.png";
import { useParams, Link } from 'react-router-dom';
import * as ROUTE from '../../../constants/routes'


const ServiceDetailContent = () => {
  const {slug} = useParams();
  let servicedetails = ''
  if(slug === 'web-development'){
      servicedetails =  (
      <>
		<div className="pb-10">
			<p className="text-lg font-semibold leading-8 tracking-tight text-blue-600">Our Services</p>
            <h2 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl ">Web Development</h2>
		</div>
		<div className="mb-4">
  			<p className="py-2">At Luday AB, we specialize in designing and developing custom web and mobile applications for individuals and businesses.</p>
   		</div>
        <div className="">
			<img className="lazy rounded-md" src={Webmobiledesign} alt=""/>
		</div>
		<div className=" pt-10">
			<p className="py-2"> Our skilled team of developers is committed to giving our clients the best web and mobile solutions suited to their individual requirements. We strive to build websites and mobile applications that are both visually appealing and sound technically.</p>
			<p className="py-2">We are aware that today's businesses must have a website or mobile application. It is crucial for establishing a brand's online presence in addition to helping to draw in potential customers. This is why our team makes a strong effort to make sure that your website or mobile application is current with both technological advancements and industry standards. We take the time to comprehend the aims and goals of your company so that we can design a website or mobile application that is both visually attractive and simple to use. </p>
			<p className="py-2">We'll collaborate with you to develop a website or mobile application that is tailored to your unique need. We can work with you to build a website or mobile application from scratch or adjust an existing website to better suit your need. In order to free up your time to run your business, our team can also maintain website. </p>
			<p className="py-2">The ability to design attractive and useful websites and mobile applications is something we take great pride in at Luday AB. We work hard to give our clients the best website design and development services available because we recognize the value of having an online presence. <Link to={ROUTE.CONTACT} className="text-blue-400">Contact us</Link> today for more information if you're looking for a web design and development team that can give you a website or mobile application that is both aesthetically pleasing and technically sound.</p>
			
		</div>
		</>
      )
    }
    else if (slug === 'embedded-software-development'){
      servicedetails =  (
      <>
        <div className="pb-10">
			<p className="text-lg font-semibold leading-8 tracking-tight text-blue-600">Our Services</p>
            <h2 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl ">Embedded Software Development</h2>
		</div>
        <div className="">
			<img className="lazy rounded-md" src={Enbededsoftware} alt=""/>
		</div>
		<div className=" pt-10">
			
			<p className="py-2">As a well-known innovative company in the creation of embedded software, Luday AB provides a wide range of services to satisfy the various needs of its clients. The skilled team of engineers at Luday AB offers a wide range of services, from technical support to the creation of custom software solutions, to guarantee the success of its clients' projects.</p>
			<p className="py-2">At Luday AB, we are dedicated to offering the best embedded software development services to our clients. Our skilled engineers are well-versed in the development of embedded software and are aware of the particular difficulties involved. We use our knowledge and experience to create unique solutions that are tailored to the needs of our clients. </p>
			<p className="py-2">To satisfy the needs of our clients, we provide a variety of services. From concept to implementation, our engineering team can offer technical guidance and assistance. For the purpose of ensuring that the software is ready for deployment, we also provide a variety of testing and debugging services. </p>
			<p className="py-2">We provide a variety of consulting services in addition to our development services. On a variety of subjects, from architecture and design to performance and optimization, our team of professionals can offer insightful counsel. To ensure the best outcome for the project, we also collaborate closely with our clients. </p>
			<p className="py-2">Last but not least, our support services guarantee that the software is kept current and error- and security-free. To make sure the system is operating at its best, we regularly update the software and offer support and advice. </p>
			<p className="py-2">With our wide range of services and expertise, Luday AB is the perfect choice for your embedded software development needs. <Link to={ROUTE.CONTACT} className="text-blue-400">Contact us</Link> today to find out how we can help you get the most out of your project. </p>
			
		</div>
		</>
  	)}
    else if (slug === 'digital-marketing'){
      servicedetails =  (
     <>
     <>
        <div className="pb-10">
			<p className="text-lg font-semibold leading-8 tracking-tight text-blue-600">Our Services</p>
            <h2 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl "> Digital Marketing</h2>
		</div>
		<div className="mb-4">
  			<p className="py-2">Businesses of all sizes are looking for strategies to increase their online presence and attract new clients as the digital world develops. An innovative digital marketing firm that can help you stay one step ahead of the competition and boost your online visibility is Luday AB. </p>
  			<p className="py-2">Our digital marketing specialists have the knowledge and skill to create a specialized action plan that is catered to your unique needs. We are aware of the value of having a strong online presence and the effects it can have on your company. Our team of qualified experts can assist you in developing a successful digital marketing campaign that will enable you to accomplish your desired objectives. </p>
   		</div>
        <div className="">
			<img className="lazy rounded-md" src={Nmarket} alt=""/>
		</div>
		<div className=" pt-10">
			<p className="py-2">We provide a range of services that can improve your online visibility. Our team of professionals can assist you in creating a thorough digital marketing strategy that covers social media management, website design, content creation, search engine optimization, and much more. </p>
			<div className="py-5">
				<img className="lazy rounded-md" src={Omarket} alt=""/>
			</div>
			<p className="py-2">Our content creation services are intended to assist you in producing interesting content that will appeal to readers and generate sales. We can work with you to develop informative and entertaining content that will make you stand out from the crowd. </p>
			<p className="py-2">Your website will be at the top of the search engine results thanks to our search engine optimization services, which will also help you increase your online visibility. To guarantee that your website shows up in the top results for pertinent searches, we employ the most recent techniques and strategies. </p>
			<p className="py-2">Finally, you can build and keep up a strong social media presence with the aid of our social media management services. Our team can assist you in developing interesting posts, answering customer questions, and developing campaigns to expand your customer base. </p>
			<p className="py-2">We at Luday AB are dedicated to assisting you in flourishing online. To learn more about our digital marketing services and how we can assist your company in achieving its objectives, <Link to={ROUTE.CONTACT} className="text-blue-400">get in touch with us</Link> right away.</p>			
		</div>
		</>
     </>   
  	)}
    else if (slug === 'ui-ux-design'){
      servicedetails =  (
      <>
        <div className="pb-10">
			<p className="text-lg font-semibold leading-8 tracking-tight text-blue-600">Our Services</p>
            <h2 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl ">UI/UX Design</h2>
		</div>
        <div className="">
			<img className="lazy rounded-md w-full h-96 object-cover" src={Uiuxdesign} alt=""/>
		</div>
		<div className=" pt-10">
			
			<p className="py-2">Businesses are looking for ways to give their customers an engaging and user-friendly experience as the digital world develops further. In order to meet the needs of its clients, Luday AB, an established leader in UI/UX design, offers a wide range of services. </p>
			<p className="py-2">We recognize the significance of designing a user-friendly environment that engages clients and motivates them to act. Your needs-specific interface can be made attractive and functional with the assistance of our team of skilled UI/UX designers. In order to make sure that your interface is both user-friendly and visually appealing, we use the most recent tools and technologies.. </p>
			<p className="py-2">Wireframing, prototyping, user testing, and user research are all included in our UI/UX design services. Our team of professionals can assist you in developing a thorough design that is customized to your particular needs. To guarantee that your design satisfies the highest standards of usability and functionality, we also offer consultation and direction. </p>
			<p className="py-2">We provide a variety of  <Link to={`${ROUTE.SERVICE_DETAILS}/web-development`} className="text-blue-400">web development</Link> services in addition to our UI/UX design services. Our team can assist you in building a fully functional, mobile- and desktop-optimized website. To make sure that your website is both safe and simple to use, we use the newest tools and technologies. </p>
			<p className="py-2">With our wide range of services and expertise, Luday AB is the perfect choice for your ui/ux design needs. <Link to={ROUTE.CONTACT} className="text-blue-400">Contact us</Link> today to find out how we can help you get the most out of your project.</p>
		</div>
		</>
    )}
    else if (slug === 'product-management'){
      servicedetails =  (
        <>
        <div className="pb-10">
			<p className="text-lg font-semibold leading-8 tracking-tight text-blue-600">Our Services</p>
            <h2 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl ">Product Management</h2>
		</div>
        <div className="">
			<img className="lazy rounded-md" src={ProjectManagement} alt=""/>
		</div>
		<div className=" pt-10">
			
			<p className="py-2">Product management is an essential component of any business but is frequently forgotten in the rush to market a new product. The process of organizing, creating, launching, and overseeing a product from conception to completion is known as product management. It is a complicated and multifaceted discipline that necessitates the coordination of numerous tasks, including user experience design, market research, product marketing, and product development. </p>
			<p className="py-2">Luday AB is a leading provider of product management as a service. We offer thorough product management services that aid in the launch of successful products for businesses. Our skilled product managers collaborate with clients to create a product roadmap and aid in the definition of the strategy and goals for their particular product. We also offer advice on market research, market analysis, and product marketing, as well as product development and design. </p>
			<p className="py-2">The product management services provided by Luday AB are customized for each client. We work together with our customers to comprehend their business objectives and create a product management strategy that works for them. In order to ensure that the product is launched and managed successfully, We also offer ongoing support. </p>
			<p className="py-2">Businesses can concentrate on their core operations while their product is being developed and managed with the help of Luday AB's product management services. We can keep concentrating on their products while getting advice and assistance from knowledgeable product managers. By doing this, companies can successfully launch their products onto the market quickly and effectively. </p>
			<p className="py-2">Experienced product managers and developers who comprehend the challenges of product management make up the team at Luday AB. We possess the experience and knowledge necessary to guarantee the success and satisfaction of their clients' products. In order to make sure that the product is constantly changing and meeting customer needs, We also offer ongoing support and feedback. </p>
			<p className="py-2">The product management services provided by Luday AB are a complete answer for companies looking to introduce a successful product. We are able to assist businesses in efficiently and quickly launching their products thanks to their skilled team of product managers. To guarantee that the product meets customer needs and is a success in the market, We can also offer ongoing support and feedback. </p>
			
			<p className="py-2">With our expertise, Luday AB is the perfect choice for your product management needs. <Link to={ROUTE.CONTACT} className="text-blue-400">Contact us</Link> today to find out how we can help you get the most out of your project.</p>
			
		</div>
		</>)
    }
    else if (slug === 'data-analytics'){
      servicedetails =  (
        <>
        <div className="pb-10">
			<p className="text-lg font-semibold leading-8 tracking-tight text-blue-600">Our Services</p>
            <h2 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl ">Data Analytics</h2>
		</div>
        <div className="">
			<img className="lazy rounded-md" src={Dataanalytics} alt=""/>
		</div>
		<div className=" pt-10">
			
			<p className="py-2">Companies can use data analytics as a potent tool to gain insights into their operations, customer behavior, and more. Luday AB offers data analytics as a service since it can be challenging for many firms to develop and maintain an effective data analytics system on their own. Companies can access robust data analytics capabilities with the help of our data analytics as a service offering without having to develop and maintain their own system. </p>
			<p className="py-2">Companies can access a wide range of analytical capabilities, such as data preparation, data visualization, predictive analytics, machine learning, and more, through the data analytics as a service offering from us. Our analytics platform is made to be highly scalable, allowing businesses to access the data analytics capabilities they require without worrying that the system won't be able to handle their growing needs. </p>
			<p className="py-2">The platform is also made to be economical. Businesses only pay for the resources they use because the platform is built to automatically scale up and down, and they only pay for the resources they actually use. This lowers the cost of the data analytics service provided by us for businesses of all sizes. </p>
			<p className="py-2">Additionally, a number of features are provided by us to keep the system performing better. Advanced analytics, automated data cleansing and transformation, data governance, and security features are some of these features. In addition to keeping the data secure and compliant with data privacy regulations, this guarantees that businesses can access the data they require. </p>
			<p className="py-2">In general, businesses looking to access robust data analytics capabilities without having to develop and maintain their own system should strongly consider our data analytics as a service offering. Companies can access a variety of analytical capabilities with the help of the platform from us, as well as gain access to cost-effectiveness and cutting-edge features. </p>
			<p className="py-2">To learn more about our data analytic services, <Link to={ROUTE.CONTACT} className="text-blue-400">get in touch with us</Link>  right away.</p>	
			
			
		</div>
		</>)
    }
    
  return (
	<>
		
	{servicedetails}
          
	</>
  );
}

export default ServiceDetailContent;
 