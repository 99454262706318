
import React  from 'react';
import { Link } from "react-router-dom";
import * as ROUTE from '../../constants/routes';
import  Codeimg from "../../images/bgimages/codeview.png";

const Deals = () => {
   return(
    <>
        <section className="overflow-hidden bg-gray-50 sm:grid sm:grid-cols-2 sm:items-center">
            <div className="p-8 md:p-12 lg:px-16 lg:py-24">
                <div className="mx-auto max-w-xl text-center sm:text-left">
                <h2 className="text-2xl font-bold text-gray-900 md:text-3xl">
                    Can't wait to build the next Big Thing?
                </h2>

                <p className="hidden text-gray-500 md:mt-4 md:block">
                We take responsibility for building customized software solutions to help transform your business activities and increase productivity.
                </p>

                <div className="mt-4 md:mt-8">
                    <Link to={ROUTE.QUOTE} className="px-8 py-4 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-[#01015D] rounded-lg hover:bg-[#3BA4E9] focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-80">
                        Request a quote
                    </Link>
                </div>
                </div>
            </div>

            <img
                alt="Luday AB"
                src={Codeimg}
                className="h-full w-full object-cover sm:h-[calc(100%_-_2rem)] sm:self-end sm:rounded-tl-[30px] md:h-[calc(100%_-_4rem)] md:rounded-tl-[60px]"
            />
            </section>

        </>
  )
}
export default Deals;
 