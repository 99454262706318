
 import React from "react";
 export const affiliateTiersData = [
  {
    level: 1,
    cons: [
      {
        title: "commission",
        body: ["15% on every product sold"],
      },
      {
        title: "sales",
        body: ["A mininimum of 10 sales to advance to Tier 2."],
      },
      {
        title: "extras",
        body: [
          "Access to high-quality marketing materials, dedicated affiliate support, and monthly performance reports.",
        ],
      },
    ],
    examples: [
      "Alex joins our affiliate program and starts at Tier 1. He promotes Luday’s products using the marketing materials provided. In his first month, Alex successfully sells 10 products. As a result, he earns a 15% commission on each sale and advances to Tier 2.",
    ],
  },
  {
    level: 2,
    cons: [
      {
        title: "commission",
        body: ["20% on every product sold"],
      },
      {
        title: "sales",
        body: ["A mininimum of 20 sales to advance to Tier 3."],
      },
      {
        title: "extras",
        body: [
          "Increased commission rate, priority support, exclusive promotional opportunities, and access to advanced analytics tools.",
        ],
      },
    ],
    examples: [
      "In Tier 2, Alex now earns a 20% commission on every product sold. With the help of advanced analytics tools and priority support, Alex boosts his sales and successfully sells 10 more products. This achievement allows him to move up to Tier 3.",
    ],
  },
  {
    level: 3,
    cons: [
      {
        title: "commission",
        body: [
          "25% on discounted prices when offering promo discount to customers",
          "20% on products when no discount is given to the customers",
        ],
      },
      {
        title: "sales",
        body: ["No specific sales target required."],
      },
      {
        title: "extras",
        body: [
          "Official company email address, premium customer support, invitations to company events or workshops, and eligibility for performance-based bonuses or incentives.",
        ],
      },
    ],
    examples: [
    "At Tier 3, Alex enjoys the highest commission rates and premium benefits. He can offer customers promotional discounts, earning a 25% commission on those sales. Additionally, Alex receives an official company email address, attends exclusive company events, and is eligible for performance-based bonuses."
    ],
  },
];
 const DetailsCard = ({type, body})=>{
   let header;
 
   switch (type) {
     case "commission":
       header = (
         <p className="font-semibold flex gap-2 text-lg items-center">
           <svg
             width="24"
             height="24"
             viewBox="0 0 16 16"
             fill="none"
              xmlns="http://www.w3.org/2000/svg"
           >
             <path
               d="M1.33337 8.7V10C1.33337 13.3333 2.66671 14.6667 6.00004 14.6667H10C13.3334 14.6667 14.6667 13.3333 14.6667 10V6.00001C14.6667 2.66668 13.3334 1.33334 10 1.33334H6.00004C2.66671 1.33334 1.33337 2.66668 1.33337 6.00001"
               stroke="#00800D"
               stroke-linecap="round"
               stroke-linejoin="round"
             />
             <path
               d="M5.54663 10.18L9.90662 5.82001"
               stroke="#00800D"
               stroke-linecap="round"
               stroke-linejoin="round"
             />
             <path
               d="M5.82001 6.91334C6.27288 6.91334 6.63999 6.54623 6.63999 6.09335C6.63999 5.64048 6.27288 5.27335 5.82001 5.27335C5.36713 5.27335 5 5.64048 5 6.09335C5 6.54623 5.36713 6.91334 5.82001 6.91334Z"
               stroke="#00800D"
               stroke-linecap="round"
               stroke-linejoin="round"
             />
             <path
               d="M10.18 10.7267C10.6328 10.7267 11 10.3595 11 9.90666C11 9.45378 10.6328 9.08667 10.18 9.08667C9.7271 9.08667 9.35999 9.45378 9.35999 9.90666C9.35999 10.3595 9.7271 10.7267 10.18 10.7267Z"
               stroke="#00800D"
               stroke-linecap="round"
               stroke-linejoin="round"
             />
           </svg>
           Commission Rate
         </p>
        );
       break;
     case "sales":
       header = (
         <p className="font-semibold flex gap-2 text-lg items-center">
           <svg
             width="24"
             height="24"
             viewBox="0 0 16 16"
             fill="none"
             xmlns="http://www.w3.org/2000/svg"
           >
             <path
               d="M1.33337 8.55336V10C1.33337 13.3333 2.66671 14.6667 6.00004 14.6667H10C13.3334 14.6667 14.6667 13.3333 14.6667 10V6.00001C14.6667 2.66668 13.3334 1.33334 10 1.33334H6.00004C2.66671 1.33334 1.33337 2.66668 1.33337 6.00001"
               stroke="#BD1700"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M6.74003 7.43335H4.97337C4.55337 7.43335 4.21338 7.77332 4.21338 8.19332V11.6066H6.74003V7.43335V7.43335Z"
              stroke="#BD1700"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M8.50673 4.39999H7.49339C7.07339 4.39999 6.7334 4.74 6.7334 5.16V11.6H9.26007V5.16C9.26007 4.74 8.92673 4.39999 8.50673 4.39999Z"
              stroke="#BD1700"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M11.0334 8.56665H9.26672V11.6H11.7934V9.32666C11.7867 8.90666 11.4467 8.56665 11.0334 8.56665Z"
              stroke="#BD1700"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          Sales Target
        </p>
      );
      break;
    case "extras":
      header = (
        <p className="font-semibold flex gap-2 text-lg items-center">
          <svg
            width="24"
            height="24"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.2467 5.92001H11.7467"
              stroke="#D7B400"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M4.2533 5.92001L4.7533 6.42001L6.2533 4.92001"
              stroke="#D7B400"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M8.2467 10.5867H11.7467"
              stroke="#D7B400"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M4.2533 10.5867L4.7533 11.0867L6.2533 9.58667"
              stroke="#D7B400"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M6.00004 14.6667H10C13.3334 14.6667 14.6667 13.3333 14.6667 10V6.00001C14.6667 2.66668 13.3334 1.33334 10 1.33334H6.00004C2.66671 1.33334 1.33337 2.66668 1.33337 6.00001V10C1.33337 13.3333 2.66671 14.6667 6.00004 14.6667Z"
              stroke="#D7B400"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          Additional Benefits
        </p>
      );
      break;
    default:
      break;
  }
    
    return(
        
     <div className="p-8 bg-white shadow-[0px_2px_4px_0_rgba(0,0,0,0.3)]  rounded-md w-80 min-w-[320px] flex-1">
      {header}
      <ul className="ml-6 mt-4">
        {body?.map((data, i) => (
          <li className="list-disc" key={i}>
            {data}
          </li>
        ))}
      </ul>
    </div>

    )
}
const Tiers = ()=>{
return(
<div>
      <div className="overflow-hidden bg-white py-0 sm:pb-5 xl:pb-12">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="lg:pr-8 lg:pt-4  justify-center i gap-10">
            {affiliateTiersData?.map((data, i) => (
              <div
                className="flex my-20 gap-10 justify-between max-[1240px]:flex-col"
                key={i}
              >
                <div className="flex gap-4 items-center w-max">
                  <div className="relative h-11 w-11 flex items-center justify-center">
                    <div className="bg-[#340E71]  h-11 w-11 rotate-45 absolute" />
                    <span className="text-white z-10 font-semibold">
                      {data.level}
                    </span>
                  </div>
                  <p className="font-bold whitespace-nowrap">
                    TIER {data.level}
                  </p>
                </div>

                <div>
                  <div className="flex gap-4 max-[1240px]:flex-wrap">
                    {data?.cons?.map((cons, i) => (
                      <DetailsCard type={cons.title} body={cons.body} key={i} />
                    ))}
                  </div>
                  <div className="mt-10">
                    {data?.examples?.map((example, i) => (
                      <p key={i}>
                        <span className="font-semibold">Example: </span>
                        {example}
                      </p>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
)
}
export default Tiers;
 