
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, FreeMode, Autoplay } from 'swiper/modules';
import Client1 from '../../images/clients/afj-logo.png'
import Client2 from '../../images/clients/dowhile-logo.png'
import Client3 from '../../images/clients/white_logo_transparent_background.png'
import Client4 from '../../images/clients/bestdeal-logo.png'
import Client5 from '../../images/clients/volvo-grouplogo.png'
import Client6 from '../../images/clients/logo_notag_neg.png'
import Client7 from '../../images/clients/mozartren.png'

const Clients=()=>{
    const features = [
        {
            name: 'AFJ FARMS',
            alt: 'Afj farms',
            icon: Client1,
            website: 'https://afjfarms.com'
        },
        {
            name: 'Do While',
            alt: 'Do While',
            icon: Client2,
            website: 'https://dowhile.se/'
        },
        {
            name: 'COMDOITY',
            alt: 'Comdoity',
            icon: Client3,
            website: 'https://comdoity.com'
        },
        {
            name: 'BESTDEALNAIJA',
            alt: 'BestDealNaija',
            icon: Client4,
            website: 'https://bestdealnaija.com/'
        },
        {
            name: 'VolvoGroup',
            alt:"Volvo Group",
            icon: Client5,
            website: "https://www.volvogroup.com/en/",
        },
        {
            name: 'VolvoGroup',
            alt:"Volvo Group",
            icon: Client6,
            website: "https://www.sigma.se/",
        },
        {
            name: 'Mozartren',
            alt:"Mozart Rengøring",
            icon: Client7,
            website: "https://mozartren.dk/",
        }
    ]
    return(
        <section id='clients' className="bg-gray-900">
          <div className="py-8 lg:py-16 mx-auto max-w-screen-xl px-4">
                <div className="mb-10">
                    <h2 className="text-lg font-semibold leading-8 tracking-tight text-[#3BA4E9]">Our Clients</h2>
                    <p className="mt-2 mb-3 text-3xl font-bold tracking-tight text-white sm:text-4xl">Past and current Clients</p>
                </div>
            
                <Swiper
                    spaceBetween={0}
                    navigation={true}
                    breakpoints={{ 
                        320: {
                            slidesPerView: 'auto'
                        },
                        480: {
                            slidesPerView: 2
                        },
                        640: {
                            slidesPerView: 3
                        },
                        920: {
                            slidesPerView: 4
                        },
                    }}
                    slidesPerView={4}
                    modules={[FreeMode, Navigation, Autoplay]}
                    freeMode={true}
                    loop={true}
                    autoplay={{
                        delay: 1,
                        pauseOnMouseEnter: true,
                        disableOnInteraction: false
                    }}
                    speed={5000}
                    className=""
                >
                    {features.map((feature) => (
                        <SwiperSlide key={feature.website} className='flex items-center justify-center overflow-hidden'>
                            <a href={feature.website} key={feature.name} rel='noreferrer' className="flex m-7" target="_blank">
                                <img src={feature.icon} alt={feature.alt} className={`grayscale-0 hover:grayscale transition-all duration-500 w-52 h-14 ${feature.name === 'Mozartren' && '!w-20 h-20 object-cover rounded-lg'}`}/>
                            </a>
                        </SwiperSlide>
                    ))}
                </Swiper>
          </div>
      </section>
    )
}

export default Clients
