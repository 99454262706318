
import './App.css'
import AppRouter from './routers/AppRouter'
import 'swiper/css'
import 'swiper/css/pagination'

function App() {
  return (
    <>
      <AppRouter />
    </>
  );
}
export default App;
