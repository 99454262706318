
import React from 'react';
import Banner from '../../../components/Banner/Banner'
import ServiveDetailsSidebar from '../../../components/Sidebars/ServiveDetailsSidebar'
import ServiceDetailContent from './ServiceDetailContent'
import { useParams } from 'react-router-dom';
import { useDocumentTitle, useScrollTop } from '../../../hooks';


const ServiceDetails = () => {
    useDocumentTitle('Services | Luday AB');
    useScrollTop();
    const {slug} = useParams();
    const removedash = slug.replace(/[-]/gi, " ");
    const newslug = removedash.replace(/\b[a-z]/g, (match) => match.toUpperCase());
    return (
        <>
        < Banner  titleText={newslug} subtitleText='Our Services'/>

        <section className="bg-white">
            <div className="max-w-7xl px-6 py-5 mx-auto">
                <div className='flex flex-col md:flex-row mt-5'>
                    <div className='w-auto md:w-3/5 m-5 md:m-10'>

                        < ServiceDetailContent/>
                    
                    </div>
                    <div className='w-auto md:w-2/5 m-5 md:m-10'>

                        < ServiveDetailsSidebar/>

                    </div>
                </div>
            </div>
        </section>
        </>
    )
}
export default ServiceDetails;
 