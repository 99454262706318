
 import React from "react";
 import Aboutusimg from "../../../images/bgimages/overview.png";
 const AffiliateOverview = ()=>{
     
     return(
      <div>
       <div className="overflow-hidden bg-white py-10 sm:py-20">
         <div className="mx-auto max-w-7xl px-6 lg:px-8">
           <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl mb-10">
             Overview
           </p>
           <div className="lg:pr-8 lg:pt-4 flex flex-col justify-center i lg:grid lg:grid-cols-2 gap-10">
             <img
               src={Aboutusimg}
               alt="Product screenshot"
               className="max-lg:object-top h-[28rem] lg:h-[30rem] xl:h-[35rem] rounded-xl shadow-xl ring-1 ring-gray-400/10 object-cover"
             />
             <div className="lg:max-w-lg">
               <div className="">
                 <p className="text-lg leading-8 text-gray-600 font-medium">
                 Welcome to Luday's comprehensive three-tier affiliate marketing program, designed to incentivize and reward affiliates for driving sales and promoting our brand. Our program is structured to provide increasing benefits and support as affiliates advance through the tiers, fostering loyalty and growth. Here’s an in-depth look at how our affiliate program works and the benefits it offers
                 </p>
               </div>
             </div>
           </div>
         </div>
       </div>
     </div>
     )
 }
 
 export default AffiliateOverview;
 