
import React from 'react';
import { useDocumentTitle, useScrollTop } from '../../../hooks';


function Portfolio() {
  useDocumentTitle('@@DocumentTitle@@');
  useScrollTop();
  return (
	<div className="app">
		@@Pos1@@
        @@Pos2@@
        @@Pos3@@
        @@Pos4@@
        @@Pos5@@
        @@Pos6@@
        @@Pos7@@
	</ div>
  )
}
export default Portfolio;
