
import React from 'react';
import { Link } from 'react-router-dom'
import Banner from '../../../components/Banner/Banner'
import Uiuxdesign from '../../../images/services/ui-ux-design.jpg'
import { useDocumentTitle } from '../../../hooks';


const Internship = () => {
  useDocumentTitle('Internship | Luday AB');
  return (
	<div className="app flex flex-col items-center justify-center">
            <div className="w-full">
                <Banner titleText={'Internship'} subtitleText={'About'} />
            </div>
            <div className="w-full max-w-[1440px] py-8 px-8">
                <div className="pb-10">
                    <p className="text-lg font-semibold leading-8 tracking-tight text-secondary">About Us</p>
                    <h2 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl ">Full-stack Developer Internship Program</h2>
                </div>
                <div className="">
                    <img className="lazy rounded-md w-full h-96 object-cover" src={Uiuxdesign} alt="" />
                </div>
                <div className=" pt-10">
                    <p className="py-2">Interns will get the opportunity to improve their experience by collaborating with other interns and also ask questions to senior developers when such questions cannot be solved within the intern teams. </p>
                    <p className="py-2">Interns are divided into scrum teams of 5 to 8 members. Each team will get a virtual private server, sprint board, Bitbucket repository, discord channel, confluence team area and access to over 7000+ courses via <Link className='font-medium hover:text-secondary' to={'https://www.pluralsight.com/'} target='_blank'>Pluralsight</Link></p>
                </div>
            </div>
        </div>
  )
}
export default Internship;
