
import React from 'react';
import Banner from '../../../components/Banner/Banner'
import ContactSidebar from '../../../components/Sidebars/ContactSidebar'
import QuoteForm from '../../../components/Forms/QuoteForm'
import { useDocumentTitle, useScrollTop } from '../../../hooks';


const Quote = () => {
 useDocumentTitle('Quote | Luday AB');
  useScrollTop();
    return (
        <>
        < Banner  titleText='Get a quote' subtitleText='quote'/>

        <section className="bg-white">
            <div className="max-w-7xl px-6 py-5 mx-auto">
                <div className='flex flex-col md:flex-row mt-5'>
                    <div className='w-auto md:w-3/5 m-5 md:m-10'>

                        < QuoteForm/>
                    
                    </div>
                    <div className='w-auto md:w-2/5 m-5 md:m-10'>

                        < ContactSidebar/>

                    </div>
                </div>
            </div>
        </section>
        </>
    )
}
export default Quote;
 