
import React, { useState } from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Testimonials({ testimonies, page, titleText, subtitleText }) {
	const [slide, setSlide] = useState(0);

	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
		autoplay: true,
		autoplaySpeed: 5000,
		infinite: true,
		adaptiveHeight: true,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 2,
					infinite: !0,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					initialSlide: 1,
				},
			},
		],
		beforeChange: (prev, next) => {
			setSlide(next);
		},
		appendDots: (dots) => (
			<div
				style={{
					borderRadius: "10px",
					padding: "10px",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					gap: "10px",
				}}
			>
				<ul className="flex items-center !-mt-48 lg:!m-12 gap-3">
					{" "}
					{dots}{" "}
				</ul>
			</div>
		),
		customPaging: (i) => (
			<div
				className={`
            ? flex items-center gap-5 w-8 h-[10px] border-[1px] ${
				i === slide ? "bg-secondary" : "bg-primary"
			}
            `}
			/>
		),
	};

	return (
		<section className="">
			<div className={`container py-10 flex flex-col gap-10 ${page !== 'internship' && 'max-w-7xl px-6 mx-auto'}`}>
				<div>
					<h2 className="text-lg font-semibold leading-8 tracking-tight text-secondary">
						{subtitleText}
					</h2>
					<p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
						{titleText}
					</p>
				</div>

				<div className="block w=full">
					<Slider {...settings}>
						{testimonies.map((testimony) => (
							<div key={testimony.id} className="p-2 w-full">
								<div className="py-4 px-8 bg-white shadow-md flex flex-col gap-6 rounded-lg my-20">
									<div className="flex justify-start -mt-16">
										<img
											className={`w-20 h-20 object-contain rounded-full border-2 border-secondary ${page !== 'internship' && 'p-1'}`}
											src={testimony.image}
                                            alt={testimony.name}
										/>
									</div>
									<div>
										<h2 className="text-gray-800 text-2xl font-semibold">
											{testimony.title}
										</h2>
										<p className="mt-2 text-gray-600">
											{testimony.content}
										</p>
									</div>
									<div className="flex flex-col mt-2">
										<a
											href="#"
											className="text-xl font-medium text-secondary"
										>
											{testimony.name}
										</a>
										<p className="text-gray-400">
											{testimony.jd}
										</p>
									</div>
								</div>
							</div>
						))}
					</Slider>
				</div>
			</div>
		</section>
	);
}

export default Testimonials;
