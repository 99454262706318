
import React from 'react'
import Bestdeal from '../../images/portfolio/bestdeal.png'
import Comdoity from '../../images/portfolio/comdoity.png'
import AfjFarm from '../../images/portfolio/afjfarms.png'
import Mozartren from '../../images/portfolio/mozartren.png'

const works = [
        {
            id: "1",
            name: 'AFJ FARMS',
            description:
            "AFJ Farms is a leading poultry farm determined to transform Agriculture in Nigeria by undertaking opportunities for growth and business.",
            link: "https://afjfarms.com",
            imglink: AfjFarm
        },
        {
            id: "2",
            name: 'COMDOITY',
            description:
            "An online platform for people to exchange their skills and time for free and network as they do so.",
            link: "https://comdoity.com",
            imglink: Comdoity
        },
        {
            id: "3",
            name: 'BestDealNaija',
            description:
            "An online platform that connects customers to business in naija. where they can buy goods and services directly from the website",
            link: "https://bestdealnaija.com/",
            imglink: Bestdeal
        },
        {
            id: "4",
            name: 'Mozart Rengøring',
            description:
            "Experience impeccable cleaning for your home and office with Mozart Cleaning",
            link: "https://mozartren.dk/",
            imglink: Mozartren
        },
    ]

const Portfolio = () => {
  return (
    <section id='portfolio' className="bg-white">
        <div className="container max-w-7xl px-6 py-10 mx-auto">
            <h2 className="text-lg font-semibold leading-8 tracking-tight text-[#3BA4E9]">Our Works</h2>
            <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">A collection of our work that highlights our expertise. Explore the projects</p>
            <div className="grid grid-cols-1 gap-8 mt-8 xl:mt-12 xl:gap-12 md:grid-cols-2 xl:grid-cols-2">
                {works.map((work) => (
                <div className="relative" key={work.id}>
                    <img src={work.imglink} alt={work.name} className="opacity-100 block w-full h-auto transition duration-700 ease-in-out rounded-xl hover:opacity-5"/>
                    <div className="absolute top-0 left-0 h-full w-full bg-gray-200 opacity-0 hover:opacity-80 flex flex-col items-center justify-center transition duration-700 ease-in-out rounded-xl border-2 border-blue-400">
                        <h5 className='text-3xl text-blue-700 font-bold'> {work.name}</h5>
                        <p className='text-center py-5 text-blue-700'>{work.description}</p>
                        <a href={work.link} target="_blank" rel='noreferrer'>
                            <div className="bg-blue-600 text-white text-base opacity-100 p-3 flex items-center rounded-full">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-link-45deg" viewBox="0 0 16 16"> <path d="M4.715 6.542 3.343 7.914a3 3 0 1 0 4.243 4.243l1.828-1.829A3 3 0 0 0 8.586 5.5L8 6.086a1.002 1.002 0 0 0-.154.199 2 2 0 0 1 .861 3.337L6.88 11.45a2 2 0 1 1-2.83-2.83l.793-.792a4.018 4.018 0 0 1-.128-1.287z" fill="white"></path> <path d="M6.586 4.672A3 3 0 0 0 7.414 9.5l.775-.776a2 2 0 0 1-.896-3.346L9.12 3.55a2 2 0 1 1 2.83 2.83l-.793.792c.112.42.155.855.128 1.287l1.372-1.372a3 3 0 1 0-4.243-4.243L6.586 4.672z" fill="white"></path> </svg>
                            Visit</div>
                        </a>
                    </div>
                </div>
                ))}
            </div>
        </div>
    </section>
  )
}

export default Portfolio
 