
 import React from "react";
 import Aboutusimg from "../../../images/bgimages/affiliate.png";
 const AffiliateHero = ()=>{
     
     return(
      <div>
      <div className="overflow-hidden bg-white py-10 sm:pt-20 xl:pt-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="lg:pr-8 lg:pt-4 flex flex-col justify-center i lg:grid lg:grid-cols-2 gap-10">
            <div className="lg:max-w-lg">
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                Simplified Affiliate Marketing
              </p>
              <div className="mt-20">
                <p className="my-6 text-lg leading-8 text-gray-600">
                  A comprehensive guide to our three-tier affiliate marketing program, Just for you.
                </p>
                <button className="bg-[#3BA4E9] p-4 rounded-md text-white font-medium">
                 Discover more below
                </button>
              </div>
            </div>
            <img
              src={Aboutusimg}
              alt="Product screenshot"
              className= "max-lg:object-top h-[28rem] lg:h-[30rem] xl:h-[35rem] rounded-xl shadow-xl ring-1 ring-gray-400/10 object-cover"
            />
          </div>
        </div>
      </div>
    </div>
     )
 }
 
 export default AffiliateHero;
 