
import React from 'react';
import { Outlet, useLocation } from "react-router-dom";

import { Navigation, Footer } from '../components/default';


const StaticRoute = () => {

const location = useLocation();

  return (
    <>
      <Navigation />
        <main className={`${location.pathname !== "/" ? "mt-16" : "mt-0"}`}>
        <Outlet />
      </main>
      <Footer />
    </>
  )
};

export default StaticRoute;
 