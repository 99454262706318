
import React from 'react';
import { Link } from 'react-router-dom'
import { notFoundIllustration } from '../../images/notfound'
import { useDocumentTitle, useScrollTop } from '../../hooks';


const NotFound = () => {
    useDocumentTitle('Not Found | Luday AB');
    useScrollTop();
    return (
        <>
         <div className="h-screen flex flex-col items-center justify-center px-6 py-0 lg:py-4 lg:px-8 pb-20">
        <img
          src={notFoundIllustration}
          alt='Not Found'
          className="w-[526px] h-[526px] grayscale object-contain"
        />
        <div className="text-black max-w-[313px] text-center space-y-3">
          <p className="text-xl font-semibold ">Page not found</p>
          <p className="font-medium">
            This Page doesn`t exist or was removed! <br /> We suggest you back to home.
          </p>
          <Link
            to={"/"}
            className="flex gap-2 items-center px-4 py-2 justify-center font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-[#3BA4E9] rounded-lg hover:bg-[#01015D] focus:outline-none focus:ring focus:ring-[#01015D] focus:ring-opacity-80"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-6 h-6"
            >
              <path
                fillRule="evenodd"
                d="M7.28 7.72a.75.75 0 0 1 0 1.06l-2.47 2.47H21a.75.75 0 0 1 0 1.5H4.81l2.47 2.47a.75.75 0 1 1-1.06 1.06l-3.75-3.75a.75.75 0 0 1 0-1.06l3.75-3.75a.75.75 0 0 1 1.06 0Z"
                clipRule="evenodd"
              />
            </svg>

            <span>Back to home.</span>
          </Link>
        </div>
      </div>
        </>
    )
}
export default NotFound;
