
import {React, useEffect} from 'react'
import ServiceCard from './ServicesCard'
import PropType from 'prop-types';

const ServicesContainer = ({ bgColor, textClass, discriptionClass }) => {
useEffect(() => {
    if (window.location.href.includes("/services")) {
            document.getElementById("heading").style.display = "none";
            document.getElementById("paragraph").style.display = "none";
        }
  }, []);

  return (
    <section className={bgColor}>
            <div className="container max-w-7xl px-6 py-10 mx-auto">
                <h2 className="text-lg font-semibold leading-8 tracking-tight text-[#3BA4E9]" id="heading">Our Solutions</h2>
                <p className={textClass} id="paragraph" >What we Offer</p>
                
                <p className={discriptionClass}>
                    We take responsibility for building customized software solutions to help transform your business activities and increase productivity.
                </p>
                <ServiceCard/>
            </div>
        </section>
  )
}
ServicesContainer.defaultProps = {
    bgColor: 'bg-gray-900',
    textClass: 'mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl',
    discriptionClass: 'mt-4 xl:mt-6 text-gray-300'
};

ServicesContainer.propTypes = {
    bgColor: PropType.string,
    textClass: PropType.string,
    discriptionClass: PropType.string
};
export default ServicesContainer
 