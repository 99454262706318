
 import React from "react";
 import Aboutusimg from "../../../images/bgimages/affiliate.png";
 const AffiliateExtra = ()=>{
     
     return(
        <div className="overflow-hidden bg-white pb-24 sm:pb-20 xl:pb-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl mb-10">
          Sourced Affiliate Program
        </p>

        <p>
          We also offer a unique opportunity for sourced affiliate marketers to
          start directly from Tier 2. These affiliates are pre-selected through
          a rigorous scrutiny process, ensuring they have the potential to drive
          significant sales.
        </p>
        <p className="mt-10">
          <span className="font-semibold">Example:</span> Jordan, a digital marketing expert, is recruited as a sourced
          affiliate. Due to Jordan’s proven track record and expertise, she
          starts at Tier 2 with a 20% commission rate and immediate access to
          priority support and exclusive promotional opportunities. Jordan
          leverages these resources to quickly generate significant sales and
          advance to Tier 3, where she enjoys maximum benefits.
        </p>
      </div>

      <div className="mx-auto max-w-7xl px-6 lg:px-8 mt-20">
        <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl mb-10">
          Still have questions?
        </p>

        <p>
          Kindly email us at {""}
          <a
            href="mailto:info@luday.se"
            className="text-[#3BA4E9] font-semibold"
          >
            info@luday.se
          </a>{" "}
          for any questions or enquiry
        </p>
      </div>
    </div>
     )
 }
 
 export default AffiliateExtra;
  